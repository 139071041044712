import { A, Navigate, redirect } from "@solidjs/router";
import Counter from "~/components/Counter";

import { createResource } from "solid-js";
import { readdir } from "fs/promises";
import path from "path";


export default function About() {

  const [chronicleFiles] = createResource(async () => {
    'use server';
    try {

      const files = await readdir(path.join(process.cwd(), "public", "chronicles"))


      return files.filter((file) => file.endsWith(".html"));
    } catch (error) {
      console.error("Error reading chronicles directory:", error);
      return [];
    }
  });



  return <main class="text-center mx-auto text-gray-700 p-4">
    <h1 class="max-6-xs text-6xl text-sky-700 font-thin uppercase my-16">
      Earlier Chronicles
    </h1>
    <ul>
      {chronicleFiles()?.map((file) => <li>
        <a href={`https://fff.dev/chronicles/${file}`}>{file}</a></li>)}
    </ul>
  </main>


}
